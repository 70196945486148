export enum AppRoutes {
    PLAYERS_RATING = 'PLAYERS_RATING',
    PLAYER_DETAIL = 'PLAYER_DETAIL',
    GAME_RESULTS = 'GAME_RESULTS',
    ABOUT = 'ABOUT',
    LOGIN = 'LOGIN',
    TEAMS = 'TEAMS',
    OVERALL_RATING = 'OVERALL_RATING',
    VIDEO = 'videos',
    CHARTS = 'CHARTS',
    BEKBOLPAGE = "BEKBOLPAGE"

}

export const getRouteMainPage = () => '/'
export const getRoutePlayerDetail = (playerId: string | number, playerGeneratedLevel: string | number,) => `/player_detail/${playerId}/${playerGeneratedLevel}`
export const getRouteGameResults = () => '/gameResults'
export const getRouteAbout = () => '/about'
export const getRouteLogin = () => '/login'
export const getRouteTeams = () => '/teams'
export const getRouteOverAllRating = () => '/overall_rating'
export const getRouteVideosPage = () => '/videos'
export const getRoutePlayerCharts = () => '/charts'
export const getRouteBekbolPage = () => '/bekbol'

