import { Select } from 'antd'
import { AxiosResponse } from 'axios'
import { memo, useEffect, useMemo } from 'react'
import api from '../../../../shared/api/api'
import useApiRequest from '../../../../shared/lib/hooks/useApiRequest'
import { useAppDispatch } from '../../../../shared/lib/hooks/useTheme/useAppDispatch'
import { SeasonsActions } from '../../model/slices/SeasonsSlice'

interface SeasonsProps {
    className?: string
}
interface SeasonDataType {
    id: string
    startDate: string
    expiredDate: string
    name: string
}
export async function getSeasons(): Promise<AxiosResponse<SeasonDataType[]>> {
    return api.get<SeasonDataType[]>(`/api/season`)
}

const Seasons = memo((props: SeasonsProps) => {
    const dispatch = useAppDispatch()

    const handleChange = (value: string) => {
        setTimeout(() => {
            dispatch(SeasonsActions.setSeasonId(value))
        }, 500)
    }

    const { data, error, fetchData } = useApiRequest(getSeasons)

    const seasonsData = data?.data

    const lastSeasonId = useMemo(() => {
        return seasonsData?.[0]?.id || null
    }, [seasonsData])

    const options = useMemo(() => {
        return seasonsData?.map((item) => ({
            value: item.id,
            label: item.name
        }))
    }, [seasonsData])

    useEffect(() => {
        fetchData()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!lastSeasonId) {
            return
        }
        dispatch(SeasonsActions.setSeasonId(lastSeasonId))
        //eslint-disable-next-line
    }, [lastSeasonId])

    if (error) {
        return <>ошибка</>
    }
    return (
        <Select
            key={lastSeasonId}
            defaultValue={lastSeasonId}
            style={{ width: '100%' }}
            onChange={handleChange}
            options={options}
        />
    )
})

export default Seasons
