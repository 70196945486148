import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Table, Tag } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { ColumnsType } from 'antd/es/table'
import { memo, useMemo } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect'
import { getPlayerDetailThunk } from '../../../../entities/PlayerDetail/model/service'
import { PlayerDetailPopup } from '../../../../entities/PlayerDetailPopup'
import { PlayerDetailPopupActions } from '../../../../entities/PlayerDetailPopup/model/slices/PlayerDetailPopupSlice'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { getRoutePlayerDetail } from '../../../../shared/lib/const/router'
import {
    assignPlayerLevels,
    getLevelLabel
} from '../../../../shared/lib/helpers/getLevelLabel'
import { useAppDispatch } from '../../../../shared/lib/hooks/useTheme/useAppDispatch'
import MyAvatar from '../../../../shared/ui/Avatar/Avatar'
import { ErrorText } from '../../../../shared/ui/ErrorText/ErrorText'
import { Loader } from '../../../../shared/ui/Loader/Loader'
import { MbLogo } from '../../../../shared/ui/MbLogo/MbLogo'
import { MyButton } from '../../../../shared/ui/MyButton/MyButton'
import { getUserAuth } from '../../../AuthByUsername'
import { getPlayersdata } from '../../model/selectors/getPlayersdata'
import { PlayerNew } from '../../model/types/PlayersSchema'
import cls from './Players.module.scss'

interface PlayersProps {
    openEditModal?: (item: PlayerNew) => void
    handleDelete?: (item: PlayerNew) => void
    className: string
    tableSize: SizeType
    allSeasonsData?: boolean
}

export const Players = memo((props: PlayersProps) => {
    const {
        className,
        handleDelete,
        openEditModal,
        tableSize,
        allSeasonsData
    } = props
    const { t } = useTranslation()
    const isAuth = useSelector(getUserAuth)
    const dispatch = useAppDispatch()

    const {
        dataBySeason: playersDataBySeason,
        data: playersAllSeasonsData,
        isLoading: isLoadingAllSesons,
        seasonError: error,
        seasonIsLoading: isLoadingBySeasons
    } = useSelector(getPlayersdata)

    const handleClickOpenEditModal = (record: PlayerNew) => {
        if (openEditModal) {
            openEditModal(record)
        }
        return
    }
    const handleClickDelete = (record: PlayerNew) => {
        if (handleDelete) {
            handleDelete(record)
        }
        return
    }

    const playersData = useMemo(() => {
        return allSeasonsData ? playersAllSeasonsData : playersDataBySeason
    }, [allSeasonsData, playersAllSeasonsData, playersDataBySeason])

    const isLoading = useMemo(() => {
        return allSeasonsData ? isLoadingAllSesons : isLoadingBySeasons
    }, [allSeasonsData, isLoadingAllSesons, isLoadingBySeasons])

    const playersDataByGID = playersData?.map((player) => {
        return {
            ...player
        }
    })

    const sortedDataByLevel = assignPlayerLevels(playersDataByGID)

    const sortedData = sortedDataByLevel
        .sort((a, b) => b.goalsCount - a.goalsCount) // сортируем по голоам у кого больше меньше
        .filter((player) => player.id !== 'clzme197g001kbt1hu6ns34xv') // убираем неизвестного игрока из рейтинга
    // .filter((player) => player.goalsCount !== 0) // убираем игроков у которых 0 голов

    const getPlayerData = (
        playerId: string | number,
        level: string | number
    ) => {
        dispatch(getPlayerDetailThunk(playerId))
        dispatch(PlayerDetailPopupActions.setPlayerRank(Number(level)))
        dispatch(PlayerDetailPopupActions.setVisible(true))
    }

    const columns: ColumnsType<PlayerNew> = [
        {
            title: '№', // Заголовок столбца с номерами
            render: (__: any, _: any, index: number) => index + 1, // Функция для отображения номеров,
            width: 30,
            align: 'center'
        },
        {
            title: 'Фото', // Заголовок столбца с номерами
            dataIndex: 'imagePath', // Название поля данных, которое будет использоваться для отображения номеров
            align: 'center',
            render: (_, record) => (
                <>
                    <BrowserView>
                        <Link
                            to={getRoutePlayerDetail(
                                record.id,
                                record.generatedLevel
                            )}
                        >
                            <MyAvatar url={record.imagePath} />
                        </Link>
                    </BrowserView>
                    <MobileView>
                        <div
                            onClick={() =>
                                getPlayerData(record.id, record.generatedLevel)
                            }
                        >
                            <MyAvatar url={record.imagePath} />
                        </div>
                    </MobileView>
                </>
            ),
            width: 50
        },
        {
            title: 'Имя',
            width: 400,
            dataIndex: 'name',
            key: 'name',
            render: (_, record, index) => (
                <>
                    <BrowserView>
                        <Link
                            className={cls.link}
                            to={getRoutePlayerDetail(
                                record.id,
                                record.generatedLevel
                            )}
                        >
                            {record.name}
                        </Link>
                    </BrowserView>
                    <MobileView>
                        <div
                            onClick={() =>
                                getPlayerData(record.id, record.generatedLevel)
                            }
                        >
                            {record.name}
                        </div>
                    </MobileView>

                    {index === 3 && (
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(26000)
                                    .pasteString(`печатает...`, null)
                                    .pauseFor(2000)
                                    .deleteAll()
                                    .pasteString(`..!..`, null)
                                    .pauseFor(2400)
                                    .deleteAll()
                                    .start()
                            }}
                        />
                    )}
                </>
            )
        },
        {
            title: 'Голы',
            width: 70,
            dataIndex: 'goalsCount',
            key: 'goals',
            render: (record: number) => record,
            align: 'center'
        },
        {
            title: 'Г.И.Д',
            dataIndex: 'goalPercentage',
            key: 'gid',
            align: 'center',
            render: (gid: string) => {
                return (
                    <Tag bordered={false} color="geekblue">
                        {gid}
                    </Tag>
                )
            },
            sorter: {
                compare: (a, b) =>
                    Number(b.goalPercentage) - Number(a.goalPercentage),
                multiple: 1
            }
        }
    ]
    if (isAuth && !allSeasonsData) {
        columns.push({
            title: 'Действия',
            align: 'center',
            key: 'actions',
            width: '9%',
            render: (text: any, record: PlayerNew) => (
                <Space>
                    <MyButton
                        variant="clear"
                        className={cls.deleteBtn}
                        onClick={() => handleClickOpenEditModal(record)}
                    >
                        <EditOutlined />
                    </MyButton>

                    <Popconfirm
                        title="Удаление"
                        description="Вы действительно хотите удалить игрока?"
                        onConfirm={() => handleClickDelete(record)}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <MyButton className={cls.deleteBtn} variant="clear">
                            <DeleteOutlined />
                        </MyButton>
                    </Popconfirm>
                </Space>
            )
        })
    } else {
        columns.push({
            title: 'Метки',
            key: 'generatedLevel',
            dataIndex: 'generatedLevel',
            render: (generatedLevel: number, _: any, index: number) => {
                return getLevelLabel(generatedLevel)
            },
            align: 'center'
        })
    }

    const handleOpen2GIS = (event: { preventDefault: () => void }) => {
        event.preventDefault()

        const url = 'https://2gis.kg/bishkek/firm/70000001061329121'
        const appUrl = 'dgis://2gis.ru/firm/70000001061329121' // Пример URI схемы для открытия приложения 2GIS

        const now = new Date().getTime()
        setTimeout(() => {
            if (new Date().getTime() - now < 1500) {
                //@ts-ignore
                window.location = url
            }
        }, 1000)
        //@ts-ignore
        window.location = appUrl
    }

    if (error) {
        return <ErrorText />
    }

    return (
        <div className={classNames(cls.Players, {}, [className])}>
            <PlayerDetailPopup />
            <Table
                title={() => (
                    <div className={cls.table_title}>
                        <div>Рейтинг по голам:</div>
                        <div onClick={handleOpen2GIS} className={cls.mbLink}>
                            MB Sport - Магазин спортивной одежды
                        </div>
                        <div>
                            <MbLogo width="40px" />
                        </div>
                    </div>
                )}
                key={sortedData?.[0]?.name}
                locale={{
                    emptyText: t('Пусто'),
                    triggerDesc:
                        'Сортировать по коэффиценту (Забитые голы / Всего игр ) (Г.И.Д)',
                    triggerAsc:
                        'Сортировать по коэффиценту (Забитые голы / Всего игр ) (Г.И.Д)',
                    cancelSort: 'Отменить сортировку'
                }}
                loading={{
                    spinning: Boolean(isLoading),
                    indicator: <Loader />
                }}
                bordered
                footer={() => (
                    <Space>
                        <MbLogo width="100px" /> BE BETTER THAN YESTERDAY
                    </Space>
                )}
                dataSource={sortedData}
                columns={columns}
                pagination={false}
                size={tableSize}
                rowKey={'id'}
                rowHoverable={false}
                scroll={{ y: undefined, x: 400 }}
                rowClassName={(record: PlayerNew) => {
                    if (
                        record?.generatedLevel &&
                        record?.generatedLevel >= 12
                    ) {
                        return cls.gold
                    }

                    return ''
                }}
            />
        </div>
    )
})
